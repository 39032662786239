import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import MainBg from "../components/mainBg";
import NewsletterSignup from "../components/newsletterSignup";
import CallToActionGeneric from "../components/callToActionGeneric";

class WorkersCompensationInsuranceCheck extends React.Component {
  render() {
    
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Workers Comp Coverage Check - Baton Rouge | Lewis Mohr"
          meta={[{ name: "description", content: "Workers Compensation Coverage Verification will provide the name of the insurer that wrote a workers compensation policy for a specific employer on a specific date." }]}  
        />
        <MainBg mainBg={this.props.data.mainBg} alt="Construction worker on the job" />
        <div className="site-body page-content">
          <h1>Workers Comp Coverage Check</h1>
          <p>Below is the link to the Louisiana Workforce Commission database to assist you in determining whether an employer has workers compensation insurance in the state. Workers Compensation Coverage Verification will provide the name of the insurer that wrote a workers compensation policy for a specific employer on a specific date.
          </p>
          <a className="button" href="https://www.ewccv.com/cvs/?ref=http%3A%2F%2Fwww.laworks.net%2FWorkersComp%2FOWC_EmployerMenu.asp" target="_blank" rel="noopener noreferrer">Check for Coverage</a>
        </div>
        <CallToActionGeneric bg={this.props.data.callToActionBg} />
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default WorkersCompensationInsuranceCheck;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "workers-comp-coverage-check-bg.jpg" }) {
      ...mainBg
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-6.jpg" }) {
      ...mainBg
    }
  }
`;
